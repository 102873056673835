<template>
  <div id="app-header">
    <v-layout v-if="getUserType === 'admin'" wrap>
      <v-flex xs12>
        <v-navigation-drawer v-model="sideNav" fixed temporary color="#fffcf5">
          <v-layout wrap justify-center>
            <v-flex xs12 v-for="(item, i) in navadmin" :key="i" text-center pa-2 pl-0 text-none align-self-center>
              <v-layout wrap justify-center>
                <v-flex xs12 px-0 pl-2 align-self-center text-left>
                  <router-link style="text-decoration: none" :to="item.route">
                    <v-icon>{{ item.icon }}</v-icon>
                    <span :style="$route.path == item.route
      ? {
        'font-family': 'mainfont',
      }
      : {
        'font-family': 'mainfont',
      }
      " style="
                    font-size: 15px;
                    color: black;
                    letter-spacing: 1px;
                    cursor: pointer;
                  ">
                      {{ item.name }}
                    </span>
                  </router-link>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 text-center pa-2 pl-0 text-none align-self-center>
              <v-layout wrap justify-center>
                <v-flex xs12 px-0 pl-3 align-self-center text-left>
                  <span style="
                  font-size: 15px;
                  color: black;
                  letter-spacing: 1px;
                  cursor: pointer;
                " @click="appLogout()">Logout</span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-navigation-drawer>

        <v-app-bar app dark bgcustom dense flat height="50px" class="hidden-lg-and-up bgcustom">
          <v-app-bar-nav-icon @click.native="sideNav = !sideNav">
            <v-icon color="white">mdi-menu</v-icon>
          </v-app-bar-nav-icon>
          <v-toolbar-title>
            <router-link style="text-decoration: none" to="/">
              <v-layout wrap justify-center>
                <v-flex xs12>
                  <span style="
                  font-family: mainfont;
                  font-size: 14px;
                  cursor: pointer;
                  color: #ffffff;
                ">
                    FILM SHOOTING
                  </span>
                </v-flex>
              </v-layout>
            </router-link>
          </v-toolbar-title>
        </v-app-bar>
        <!-- <v-app-bar fixed app sticky elevation="0" height="90px" grad class="hidden-sm-and-down bgcustom transparent">
          <v-layout wrap justify-center text-center>
            <v-flex xs1 pl-2 align-self-center>
              <v-img height="60px" contain src="../../assets/Images/logowhite.png"></v-img>
            </v-flex>
            <v-flex xs3 pl-2 text-left align-self-center @click="gotoHome
              ">
              <span class="mainfont" style="font-weight:25px;font-size: larger;color: white;">
                FILM SHOOTING

              </span>
            </v-flex>
            <v-flex xs6 lg8 pr-10 xl10 text-right>
              <v-flex xs6 lg12 pt-2 sm6>
                <v-btn outlined color="white" class="mainfont" small @click="appLogout()">
                  <v-icon color="white" size="19">
                    mdi-account-circle-outline
                  </v-icon>
                  <span style="font-family: mainfont; color: white; font-size: 12px">Logout</span>
                </v-btn>
              </v-flex>
            </v-flex>
          </v-layout>
        </v-app-bar> -->
        <v-app-bar fixed app sticky elevation="0" height="90px" grad class="hidden-sm-and-down bgcustom transparent">
          <v-layout wrap justify-center text-center>
            <v-flex xs1 pl-2 align-self-center>
              <v-img height="60px" contain src="../../assets/Images/logowhite.png"></v-img>
            </v-flex>
            <v-flex xs3 pl-2 text-left align-self-center @click="gotoHome">
              <span class="mainfont" style="font-weight:23px;font-size: larger;color: white;">FILM SHOOTING </span>
            </v-flex>
            <v-flex xs6 lg0 sm8 pt-4 pr-10 text-right>
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn outlined color="white" class="mainfont" small v-on="on">
                    <v-icon color="white" size="19">mdi-account-circle-outline</v-icon>
                    <span style="font-family: mainfont; color: white; font-size: 12px">{{ userProfile }}</span>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-flex xs12>
                      <span style="font-family: mainfont; color: black; font-size: 14px;">Role: {{ userRole }}</span>
                    </v-flex>
                  </v-list-item>
                  <v-list-item>
                    <v-flex xs12>
                      <span style="font-family: mainfont; color: black; font-size: 14px;">Name: {{ userName }}</span>
                    </v-flex>
                  </v-list-item>
                  <!-- <v-list-item @click="appLogout">Logout</v-list-item> -->
                  <v-flex xs12 class="text-center">
                    <v-btn @click="appLogout"  color="red" outlined>Logout</v-btn>
                  </v-flex>
                </v-list>
              </v-menu>
            </v-flex>
          </v-layout>
        </v-app-bar>


      </v-flex>
    </v-layout>

    <v-layout v-else-if="getUserType === 'user'" wrap>
      <v-flex xs12>
        <v-navigation-drawer v-model="sideNav" fixed temporary color="#fffcf5">

          <v-layout wrap justify-center>
            <v-flex xs12 v-for="(item, i) in navuser" :key="i" text-center pa-2 pl-0 text-none align-self-center>
              <v-layout wrap justify-center>
                <v-flex xs12 px-0 pl-2 align-self-center text-left>
                  <router-link style="text-decoration: none" :to="item.route">
                    <v-icon>{{ item.icon }}</v-icon>
                    <span :style="$route.path == item.route
      ? {
        'font-family': 'mainfont',
      }
      : {
        'font-family': 'mainfont',
      }
      " style="
                    font-size: 15px;
                    color: black;
                    letter-spacing: 1px;
                    cursor: pointer;
                  ">
                      {{ item.name }}
                    </span>
                  </router-link>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 text-center pa-2 pl-0 text-none align-self-center>
              <v-layout wrap justify-center>
                <v-flex xs12 px-0 pl-3 align-self-center text-left>
                  <span style="
                  font-size: 15px;
                  color: black;
                  letter-spacing: 1px;
                  cursor: pointer;
                " @click="appLogout()">Logout</span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-navigation-drawer>

        <v-app-bar app dark bgcustom dense flat height="50px" class="hidden-lg-and-up bgcustom">
          <v-app-bar-nav-icon @click.native="sideNav = !sideNav">
            <v-icon color="white">mdi-menu</v-icon>
          </v-app-bar-nav-icon>
          <v-toolbar-title>
            <router-link style="text-decoration: none" to="/">
              <v-layout wrap justify-center>
                <v-flex xs12>
                  <span style="
                  font-family: mainfont;
                  font-size: 14px;
                  cursor: pointer;
                  color: #ffffff;
                ">
                    FILM SHOOTING
                  </span>
                </v-flex>
              </v-layout>
            </router-link>
          </v-toolbar-title>
        </v-app-bar>

        <!-- <v-app-bar fixed app sticky elevation="0" height="90px" grad class="hidden-sm-and-down bgcustom transparent">
          <v-layout wrap justify-center text-center>
            <v-flex xs1 pl-2 align-self-center>
              <v-img height="60px" contain src="../../assets/Images/logowhite.png"></v-img>
            </v-flex>
            <v-flex xs3 pl-2 text-left align-self-center @click="gotoHome
              ">
              <span class="mainfont" style="font-weight:23px;font-size: larger;color: white;">FILM SHOOTING </span>
            </v-flex>
            <v-flex xs6 lg0 sm8 pt-4 pr-10 text-right>
              <v-btn outlined color="white" class="mainfont" small @click="appLogout()">
                <v-icon color="white" size="19">
                  mdi-account-circle-outline
                </v-icon>
                <span style="font-family: mainfont; color: white; font-size: 12px">Logout</span>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-app-bar> -->
        <v-app-bar fixed app sticky elevation="0" height="90px" grad class="hidden-sm-and-down bgcustom transparent">
          <v-layout wrap justify-center text-center>
            <v-flex xs1 pl-2 align-self-center>
              <v-img height="60px" contain src="../../assets/Images/logowhite.png"></v-img>
            </v-flex>
            <v-flex xs3 pl-2 text-left align-self-center @click="gotoHome">
              <span class="mainfont" style="font-weight:23px;font-size: larger;color: white;">FILM SHOOTING </span>
            </v-flex>
            <v-flex xs6 lg0 sm8 pt-4 pr-10 text-right>
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn outlined color="white" class="mainfont" small v-on="on">
                    <v-icon color="white" size="19">mdi-account-circle-outline</v-icon>
                    <span style="font-family: mainfont; color: white; font-size: 12px">{{ userProfile }}</span>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-flex xs12>
                      <span style="font-family: mainfont; color: black; font-size: 14px;">Name: {{ userName }}</span>
                    </v-flex>
                  </v-list-item>
                  <v-list-item>
                    <v-flex xs12>
                      <span style="font-family: mainfont; color: black; font-size: 14px;">Role: {{ userRole }}</span>
                    </v-flex>
                  </v-list-item>
                  <!-- <v-list-item @click="appLogout">Logout</v-list-item> -->
                  <v-flex xs12 class="text-center">
                    <v-btn @click="appLogout"  color="red" outlined>Logout</v-btn>
                  </v-flex>
                </v-list>
              </v-menu>
            </v-flex>
          </v-layout>
        </v-app-bar>

      </v-flex>
    </v-layout>
    <v-layout v-else-if="getUserType === 'section'" wrap>
      <v-flex xs12>
        <v-navigation-drawer v-model="sideNav" fixed temporary color="#fffcf5">

          <v-layout wrap justify-center>
            <v-flex xs12 v-for="(item, i) in navsection" :key="i" text-center pa-2 pl-0 text-none align-self-center>
              <v-layout wrap justify-center>
                <v-flex xs12 px-0 pl-2 align-self-center text-left>
                  <router-link style="text-decoration: none" :to="item.route">
                    <v-icon>{{ item.icon }}</v-icon>
                    <span :style="$route.path == item.route
      ? {
        'font-family': 'mainfont',
      }
      : {
        'font-family': 'mainfont',
      }
      " style="
                    font-size: 15px;
                    color: black;
                    letter-spacing: 1px;
                    cursor: pointer;
                  ">
                      {{ item.name }}
                    </span>
                  </router-link>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 text-center pa-2 pl-0 text-none align-self-center>
              <v-layout wrap justify-center>
                <v-flex xs12 px-0 pl-3 align-self-center text-left>
                  <span style="
                  font-size: 15px;
                  color: black;
                  letter-spacing: 1px;
                  cursor: pointer;
                " @click="appLogout()">Logout</span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-navigation-drawer>

        <v-app-bar app dark bgcustom dense flat height="50px" class="hidden-lg-and-up bgcustom">
          <v-app-bar-nav-icon @click.native="sideNav = !sideNav">
            <v-icon color="white">mdi-menu</v-icon>
          </v-app-bar-nav-icon>
          <v-toolbar-title>
            <router-link style="text-decoration: none" to="/">
              <v-layout wrap justify-center>
                <v-flex xs12>
                  <span style="
                  font-family: mainfont;
                  font-size: 14px;
                  cursor: pointer;
                  color: #ffffff;
                ">
                    FILM SHOOTING
                  </span>
                </v-flex>
              </v-layout>
            </router-link>
          </v-toolbar-title>
        </v-app-bar>

        <!-- <v-app-bar fixed app sticky elevation="0" height="90px" grad class="hidden-sm-and-down bgcustom transparent">
          <v-layout wrap justify-center text-center>
            <v-flex xs1 pl-2 align-self-center>
              <v-img height="60px" contain src="../../assets/Images/logowhite.png"></v-img>
            </v-flex>
            <v-flex xs3 pl-2 text-left align-self-center @click="gotoHome
              ">
              <span class="mainfont" style="font-weight:23px;font-size: larger;color: white;">FILM SHOOTING </span>
            </v-flex>
            <v-flex xs6 lg0 sm8 pt-4 pr-10 text-right>
              <v-btn outlined color="white" class="mainfont" small @click="appLogout()">
                <v-icon color="white" size="19">
                  mdi-account-circle-outline
                </v-icon>
                <span style="font-family: mainfont; color: white; font-size: 12px">Logout</span>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-app-bar> -->
        <v-app-bar fixed app sticky elevation="0" height="90px" grad class="hidden-sm-and-down bgcustom transparent">
          <v-layout wrap justify-center text-center>
            <v-flex xs1 pl-2 align-self-center>
              <v-img height="60px" contain src="../../assets/Images/logowhite.png"></v-img>
            </v-flex>
            <v-flex xs3 pl-2 text-left align-self-center @click="gotoHome">
              <span class="mainfont" style="font-weight:23px;font-size: larger;color: white;">FILM SHOOTING </span>
            </v-flex>
            <v-flex xs6 lg0 sm8 pt-4 pr-10 text-right>
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn outlined color="white" class="mainfont" small v-on="on">
                    <v-icon color="white" size="19">mdi-account-circle-outline</v-icon>
                    <span style="font-family: mainfont; color: white; font-size: 12px">{{ userProfile }}</span>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-flex xs12>
                      <span style="font-family: mainfont; color: black; font-size: 14px;">Name: {{ userName }}</span>
                    </v-flex>
                  </v-list-item>
                  <v-list-item>
                    <v-flex xs12>
                      <span style="font-family: mainfont; color: black; font-size: 14px;">Role: {{ userRole }}</span>
                    </v-flex>
                  </v-list-item>
                  <!-- <v-list-item @click="appLogout">Logout</v-list-item> -->
                  <v-flex xs12 class="text-center">
                    <v-btn @click="appLogout"  color="red" outlined>Logout</v-btn>
                  </v-flex>
                </v-list>
              </v-menu>
            </v-flex>
          </v-layout>
        </v-app-bar>
      </v-flex>
    </v-layout>
    <v-layout v-else-if="getUserType === 'superclerk'" wrap>
      <v-flex xs12>
        <v-navigation-drawer v-model="sideNav" fixed temporary color="#fffcf5">

          <v-layout wrap justify-center>
            <v-flex xs12 v-for="(item, i) in navsuperclerk" :key="i" text-center pa-2 pl-0 text-none align-self-center>
              <v-layout wrap justify-center>
                <v-flex xs12 px-0 pl-2 align-self-center text-left>
                  <router-link style="text-decoration: none" :to="item.route">
                    <v-icon>{{ item.icon }}</v-icon>
                    <span :style="$route.path == item.route
      ? {
        'font-family': 'mainfont',
      }
      : {
        'font-family': 'mainfont',
      }
      " style="
                    font-size: 15px;
                    color: black;
                    letter-spacing: 1px;
                    cursor: pointer;
                  ">
                      {{ item.name }}
                    </span>
                  </router-link>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 text-center pa-2 pl-0 text-none align-self-center>
              <v-layout wrap justify-center>
                <v-flex xs12 px-0 pl-3 align-self-center text-left>
                  <span style="
                  font-size: 15px;
                  color: black;
                  letter-spacing: 1px;
                  cursor: pointer;
                " @click="appLogout()">Logout</span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-navigation-drawer>

        <v-app-bar app dark bgcustom dense flat height="50px" class="hidden-lg-and-up bgcustom">
          <v-app-bar-nav-icon @click.native="sideNav = !sideNav">
            <v-icon color="white">mdi-menu</v-icon>
          </v-app-bar-nav-icon>
          <v-toolbar-title>
            <router-link style="text-decoration: none" to="/">
              <v-layout wrap justify-center>
                <v-flex xs12>
                  <span style="
                  font-family: mainfont;
                  font-size: 14px;
                  cursor: pointer;
                  color: #ffffff;
                ">
                    FILM SHOOTING
                  </span>
                </v-flex>
              </v-layout>
            </router-link>
          </v-toolbar-title>
        </v-app-bar>

        <!-- <v-app-bar fixed app sticky elevation="0" height="90px" grad class="hidden-sm-and-down bgcustom transparent">
          <v-layout wrap justify-center text-center>
            <v-flex xs1 pl-2 align-self-center>
              <v-img height="60px" contain src="../../assets/Images/logowhite.png"></v-img>
            </v-flex>
            <v-flex xs3 pl-2 text-left align-self-center @click="gotoHome
              ">
              <span class="mainfont" style="font-weight:23px;font-size: larger;color: white;">FILM SHOOTING </span>
            </v-flex>
            <v-flex xs6 lg0 sm8 pt-4 pr-10 text-right>
              <v-btn outlined color="white" class="mainfont" small @click="appLogout()">
                <v-icon color="white" size="19">
                  mdi-account-circle-outline
                </v-icon>
                <span style="font-family: mainfont; color: white; font-size: 12px">Logout</span>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-app-bar> -->
        <v-app-bar fixed app sticky elevation="0" height="90px" grad class="hidden-sm-and-down bgcustom transparent">
          <v-layout wrap justify-center text-center>
            <v-flex xs1 pl-2 align-self-center>
              <v-img height="60px" contain src="../../assets/Images/logowhite.png"></v-img>
            </v-flex>
            <v-flex xs3 pl-2 text-left align-self-center @click="gotoHome">
              <span class="mainfont" style="font-weight:23px;font-size: larger;color: white;">FILM SHOOTING </span>
            </v-flex>
            <v-flex xs6 lg0 sm8 pt-4 pr-10 text-right>
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn outlined color="white" class="mainfont" small v-on="on">
                    <v-icon color="white" size="19">mdi-account-circle-outline</v-icon>
                    <span style="font-family: mainfont; color: white; font-size: 12px">{{ userProfile }}</span>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-flex xs12>
                      <span style="font-family: mainfont; color: black; font-size: 14px;">Name: {{ userName }}</span>
                    </v-flex>
                  </v-list-item>
                  <v-list-item>
                    <v-flex xs12>
                      <span style="font-family: mainfont; color: black; font-size: 14px;">Role: {{ userRole }}</span>
                    </v-flex>
                  </v-list-item>
                  <!-- <v-list-item @click="appLogout">Logout</v-list-item> -->
                  <v-flex xs12 class="text-center">
                    <v-btn @click="appLogout"  color="red" outlined>Logout</v-btn>
                  </v-flex>
                </v-list>
              </v-menu>
            </v-flex>
          </v-layout>
        </v-app-bar>
      </v-flex>
    </v-layout>
    <v-layout v-else-if="getUserType === 'range'" wrap>
      <v-flex xs12>
        <v-navigation-drawer v-model="sideNav" fixed temporary color="#fffcf5">

          <v-layout wrap justify-center>
            <v-flex xs12 v-for="(item, i) in navrange" :key="i" text-center pa-2 pl-0 text-none align-self-center>
              <v-layout wrap justify-center>
                <v-flex xs12 px-0 pl-2 align-self-center text-left>
                  <router-link style="text-decoration: none" :to="item.route">
                    <v-icon>{{ item.icon }}</v-icon>
                    <span :style="$route.path == item.route
      ? {
        'font-family': 'mainfont',
      }
      : {
        'font-family': 'mainfont',
      }
      " style="
                    font-size: 15px;
                    color: black;
                    letter-spacing: 1px;
                    cursor: pointer;
                  ">
                      {{ item.name }}
                    </span>
                  </router-link>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 text-center pa-2 pl-0 text-none align-self-center>
              <v-layout wrap justify-center>
                <v-flex xs12 px-0 pl-3 align-self-center text-left>
                  <span style="
                  font-size: 15px;
                  color: black;
                  letter-spacing: 1px;
                  cursor: pointer;
                " @click="appLogout()">Logout</span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-navigation-drawer>

        <v-app-bar app dark bgcustom dense flat height="50px" class="hidden-lg-and-up bgcustom">
          <v-app-bar-nav-icon @click.native="sideNav = !sideNav">
            <v-icon color="white">mdi-menu</v-icon>
          </v-app-bar-nav-icon>
          <v-toolbar-title>
            <router-link style="text-decoration: none" to="/">
              <v-layout wrap justify-center>
                <v-flex xs12>
                  <span style="
                  font-family: mainfont;
                  font-size: 14px;
                  cursor: pointer;
                  color: #ffffff;
                ">
                    FILM SHOOTING
                  </span>
                </v-flex>
              </v-layout>
            </router-link>
          </v-toolbar-title>
        </v-app-bar>

        <!-- <v-app-bar fixed app sticky elevation="0" height="90px" grad class="hidden-sm-and-down bgcustom transparent">
          <v-layout wrap justify-center text-center>
            <v-flex xs1 pl-2 align-self-center>
              <v-img height="60px" contain src="../../assets/Images/logowhite.png"></v-img>
            </v-flex>
            <v-flex xs3 pl-2 text-left align-self-center @click="gotoHome
              ">
              <span class="mainfont" style="font-weight:23px;font-size: larger;color: white;">FILM SHOOTING </span>
            </v-flex>
            <v-flex xs6 lg0 sm8 pt-4 pr-10 text-right>
              <v-btn outlined color="white" class="mainfont" small @click="appLogout()">
                <v-icon color="white" size="19">
                  mdi-account-circle-outline
                </v-icon>
                <span style="font-family: mainfont; color: white; font-size: 12px">Logout</span>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-app-bar> -->
        <v-app-bar fixed app sticky elevation="0" height="90px" grad class="hidden-sm-and-down bgcustom transparent">
          <v-layout wrap justify-center text-center>
            <v-flex xs1 pl-2 align-self-center>
              <v-img height="60px" contain src="../../assets/Images/logowhite.png"></v-img>
            </v-flex>
            <v-flex xs3 pl-2 text-left align-self-center @click="gotoHome">
              <span class="mainfont" style="font-weight:23px;font-size: larger;color: white;">FILM SHOOTING </span>
            </v-flex>
            <v-flex xs6 lg0 sm8 pt-4 pr-10 text-right>
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn outlined color="white" class="mainfont" small v-on="on">
                    <v-icon color="white" size="19">mdi-account-circle-outline</v-icon>
                    <span style="font-family: mainfont; color: white; font-size: 12px">{{ userProfile }}</span>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-flex xs12>
                      <span style="font-family: mainfont; color: black; font-size: 14px;">Name: {{ userName }}</span>
                    </v-flex>
                  </v-list-item>
                  <v-list-item>
                    <v-flex xs12>
                      <span style="font-family: mainfont; color: black; font-size: 14px;">Role: {{ userRole }}</span>
                    </v-flex>
                  </v-list-item>
                  <!-- <v-list-item @click="appLogout">Logout</v-list-item> -->
                  <v-flex xs12 class="text-center">
                    <v-btn @click="appLogout"  color="red" outlined>Logout</v-btn>
                  </v-flex>
                </v-list>
              </v-menu>
            </v-flex>
          </v-layout>
        </v-app-bar>
      </v-flex>
    </v-layout>
    <v-layout v-else wrap>
      <v-flex xs12>
        <v-navigation-drawer v-model="sideNav" fixed temporary color="#fffcf5">
          <v-layout wrap justify-center>
            <v-flex xs12 v-for="(item, i) in navdivision" :key="i" text-center pa-2 pl-0 text-none align-self-center>
              <v-layout wrap justify-center>
                <v-flex xs12 px-0 pl-2 align-self-center text-left>
                  <router-link style="text-decoration: none" :to="item.route">
                    <v-icon>{{ item.icon }}</v-icon>
                    <span :style="$route.path == item.route
      ? {
        'font-family': 'mainfont',
      }
      : {
        'font-family': 'mainfont',
      }
      " style="
                    font-size: 15px;
                    color: black;
                    letter-spacing: 1px;
                    cursor: pointer;
                  ">
                      {{ item.name }}
                    </span>
                  </router-link>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 text-center pa-2 pl-0 text-none align-self-center>
              <v-layout wrap justify-center>
                <v-flex xs12 px-0 pl-3 align-self-center text-left>
                  <span style="
                  font-size: 15px;
                  color: black;
                  letter-spacing: 1px;
                  cursor: pointer;
                " @click="appLogout()">Logout</span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-navigation-drawer>
        <v-app-bar app dark bgcustom dense flat height="50px" class="hidden-lg-and-up bgcustom">
          <v-app-bar-nav-icon @click.native="sideNav = !sideNav">
            <v-icon color="white">mdi-menu</v-icon>
          </v-app-bar-nav-icon>
          <v-toolbar-title>
            <router-link style="text-decoration: none" to="/">
              <v-layout wrap justify-center>
                <v-flex xs12>
                  <span style="
                  font-family: mainfont;
                  font-size: 14px;
                  cursor: pointer;
                  color: #ffffff;
                ">
                    FILM SHOOTING
                  </span>
                </v-flex>
              </v-layout>
            </router-link>
          </v-toolbar-title>
        </v-app-bar>
        <!-- <v-app-bar fixed app sticky elevation="0" height="90px" grad class="hidden-sm-and-down bgcustom transparent">
          <v-layout wrap justify-center text-center>
            <v-flex xs1 pl-2 align-self-center>
              <v-img height="60px" contain src="../../assets/Images/logowhite.png"></v-img>
            </v-flex>
            <v-flex xs3 pl-2 text-left align-self-center @click="gotoHome
              ">
              <span class="mainfont" style="font-size: larger;color: white;"> FILM SHOOTING APPLICATION</span>
            </v-flex>
            <v-flex lg8 pt-5 pr-12 text-right>
              <v-btn outlined color="white" class="mainfont" small @click="appLogout()">
                <v-icon color="white" size="19">
                  mdi-account-circle-outline
                </v-icon>
                <span style="font-family: mainfont; color: white; font-size: 12px">Logout</span>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-app-bar> -->
        <v-app-bar fixed app sticky elevation="0" height="90px" grad class="hidden-sm-and-down bgcustom transparent">
          <v-layout wrap justify-center text-center>
            <v-flex xs1 pl-2 align-self-center>
              <v-img height="60px" contain src="../../assets/Images/logowhite.png"></v-img>
            </v-flex>
            <v-flex xs3 pl-2 text-left align-self-center @click="gotoHome">
              <span class="mainfont" style="font-weight:23px;font-size: larger;color: white;">FILM SHOOTING </span>
            </v-flex>
            <v-flex xs6 lg0 sm8 pt-4 pr-10 text-right>
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn outlined color="white" class="mainfont" small v-on="on">
                    <v-icon color="white" size="19">mdi-account-circle-outline</v-icon>
                    <span style="font-family: mainfont; color: white; font-size: 12px">{{ userProfile }}</span>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-flex xs12>
                      <span style="font-family: mainfont; color: black; font-size: 14px;">Role: {{ userRole }}</span>
                    </v-flex>
                  </v-list-item>
                  <v-list-item>
                    <v-flex xs12>
                      <span style="font-family: mainfont; color: black; font-size: 14px;">Name: {{ userName }}</span>
                    </v-flex>
                  </v-list-item>
                  <v-flex xs12 class="text-center">
                    <v-btn @click="appLogout"  color="red" outlined>Logout</v-btn>
                  </v-flex>
                </v-list>
              </v-menu>
            </v-flex>
          </v-layout>
        </v-app-bar>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import AccountMenu from "@/components/Common/accountMenu";
export default {
  components: {
    // AccountMenu,
  },
  data() {
    return {
      sideNav: false,
      scrollPosition: 0,
      navuser: [
        {
          name: "NEW APPLICATION",
          route: "/booknow",
          src: require("../../assets/Images/sideicon4.png"),
        },
        {
          name: "MY APPLICATIONS",
          route: "/mybooking",
          src: require("../../assets/Images/sideicon3.png"),
        },
        {
          name: "FORWARDED APPLICATIONS",
          route: "/ForwardedApplications",
          src: require("../../assets/Images/sideicon2.png"),
        },
        {
          name: "CANCELLED APPLICATIONS",
          route: "/cancelledapplications",
          src: require("../../assets/Images/request.png"),
        },
      ],
      navadmin: [
        {
          name: "DASHBOARD",
          route: "/DashboardPage",
          src: require("../../assets/Images/sideicon1.png"),
        },

        {
          name: "ADD NATURE CAMP",
          route: "/addcamp",
          src: require("../../assets/Images/sideicon4.png"),
        },
        {
          name: "MANAGE NATURE CAMP",
          route: "/adminnaturecamp",
          src: require("../../assets/Images/sideicon2.png"),
        },
        {
          name: "PENDING APPLICATIONS",
          route: "/PendingApplicationsAdmin",
          src: require("../../assets/Images/pendingicon.png"),
        },
        {
          name: "APPLICATIONS",
          route: "/AdminBookinsPage",
          src: require("../../assets/Images/sideicon3.png"),
        },
      ],
      navsection: [
        {
          name: "APPLICATIONS",
          route: "/sectionhome",
          src: require("../../assets/Images/sideicon1.png"),

        },
      ],
      navrange: [
        {
          name: "APPLICATION",
          route: "/rangebookings",
          src: require("../../assets/Images/cashier2.png"),
        },
      ],
      navsuperclerk: [
        {
          name: "APPLICATIONS",
          route: "/superintedent",
          src: require("../../assets/Images/sideicon1.png"),

        },
      ],
      navdivision: [
        {
          name: "DASHBOARD",
          route: "/divisiondashboard",
          src: require("../../assets/Images/sideicon1.png"),

        },
        {
          name: "ADD SECTION CLERK",
          route: "/sectionclerk",
          src: require("../../assets/Images/cashier.png"),

        },
        {
          name: "ADD SUPERINTENDENT ",
          route: "/superintedentdivision",
          src: require("../../assets/Images/cashier2.png"),

        },

        {
          name: " APPLICATIONS",
          route: "/ApplicationDivision",
          src: require("../../assets/Images/sideicon5.png"),
        },
        {
          name: "CANCEL REQUESTS",
          route: "/cancelrequests",
          src: require("../../assets/Images/request.png"),
        },
      ],
    };
  },
  computed: {
    getUserType() {
      const uType = localStorage.getItem("uType");
      if (uType === "777") {
        return "admin";
      } else if (uType === "772") {
        return "user";


      }
      else if (uType === "773") {
        return "range";

      }
      else if (uType === "7741") {
        return "section";
      }
      else if (uType === "7742") {
        return "superclerk";
      }
      else {
        return "division";
      }
    },
    userRole() {
      return this.$store.state.role;
    },
    userName() {
      return this.$store.state.name;
    },
    userProfile() {
      return `${this.userName} - ${this.userRole}`;
    },
  },

  methods: {
    gotoHome() {
      this.$router.push({
        path: '/',
        query: { status: 'Pending', fromDashboard2: true },
      });
    },
    appLogout() {
      this.$store.commit("logoutUser", true);
    },
  },
};
</script>
<style>
.mainbg {
  background-image: linear-gradient(269.6deg, #29807c -31.66%, #29807c);
}

input::-webkit-input-placeholder {
  color: #c5c5c5 !important;
  font-size: 14px !important;
  font-family: poppinsregular !important;
}

input::-moz-placeholder {
  color: #c5c5c5 !important;
  font-size: 14px !important;
  font-family: poppinsregular !important;
}

.custom-app-bar {
  z-index: 1000;
  /* Adjust the z-index value as needed */
}

.searchBox .v-input__control {
  min-height: 10px !important;
}

/* #app-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  background-color: #333;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
} */
.searchBox .v-input__control .v-input__slot {
  padding: 0px !important;
}
</style>